
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { Options, Vue } from "vue-class-component";
import SectionTotalTicket from "../components/section-total-ticket.vue";
import SectionPercentageDonutChart from "../components/section-percentage-donut-chart.vue";
import SectionFakeDex from "../components/section-fake-dex.vue";
import SectionAttempt from "../components/section-attempt.vue";
import SectionBacklog from "../components/section-backlog.vue";
import SectionBreakdownTicketDex from "../components/section-breakdown-ticket-dex.vue";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import Checkbox from "primevue/checkbox";
import { donutChartData } from "../components/misc";
import capitalize from "lodash/capitalize";

@Options({
  components: {
    SectionTotalTicket,
    SectionPercentageDonutChart,
    SectionFakeDex,
    SectionBreakdownTicketDex,
    Checkbox,
    SectionBacklog,
    SectionAttempt
  }
})
export default class DexAssesmentTab extends Vue {
  get title(): string {
    return (
      DexAssessmentController.dashboardTabs.find(
        e => e.path === DexAssessmentController.dashboardCurrentTabActive
      )?.title || ""
    );
  }

  // date
  get filter() {
    return DexAssessmentController.filter;
  }
  reRenderComponent = true;
  onSelectDatePicker() {
    this.reRenderComponent = false;
    this.$nextTick(() => {
      this.reRenderComponent = true;
    });
  }

  async setDateRange(value: any[]) {
    DexAssessmentController.setStartDate(value[0]);
    DexAssessmentController.setEndDate(value[1]);

    const valid = value[0] && value[1];
    if (valid) {
      this.onSelectDatePicker();
      this.fetchData();
    }
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.dashboardData.loading = true;
      const {
        data: summaryTicketPercentage
      } = await DexAssessmentController.getSummaryTicketPercentage(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate)
        })
      );
      this.onMappingSummaryTicketPercentage(summaryTicketPercentage);
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }
  onMappingSummaryTicketPercentage(data: any): void {
    for (const [key] of Object.entries(data)) {
      this.donutChartData.forEach((e: any, i: number) => {
        if (e.key === key) {
          this.onMappingDonutChartValue(data, i);
          this.onMappingDonutChartDetail(data, i);

          /* total ticket */
          const total = this.donutChartData[i].total;
          if (total) {
            this.donutChartData[i].total.value = data[total.key];
          }

          const percentage = this.donutChartData[i].percentage;
          if (percentage) {
            this.donutChartData[i].percentage.value = data[percentage.key];
          }
          /* end total ticket */
        }
      });
    }
  }
  onMappingDonutChartValue(data: any, i: number): void {
    /* donut chart */
    if (this.donutChartData[i].data) {
      const tempData = [];
      const donutData: any = this.donutChartData[i].dataKey;

      for (const item of donutData) {
        tempData.push(data[item]);
      }

      this.donutChartData[i].data = tempData;
    }
    /* end donut chart */
  }
  onMappingDonutChartDetail(data: any, i: number): void {
    /* detail donut chart */
    if (this.donutChartData[i].detail) {
      const detail: any = this.donutChartData[i].detail;

      detail.forEach((item: any, index: number) => {
        this.donutChartData[i].detail[index] = {
          ...detail[index],
          value: data[item.key],
          percentage: new OptionsClass({
            ...item.percentage,
            value: data[item.percentage.key]
          })
        };
      });
    }
    /* end detail donut chart */
  }

  // donut chart
  donutChartData: any[] = donutChartData;

  backlogType = ["l1", "l2"];
  capitalize(value: string) {
    return capitalize(value);
  }

  onDownload(endpoint: string) {
    DexAssessmentController.getDownload(endpoint);
  }
}
