import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "balance-list bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full font-montserrat" }
const _hoisted_2 = {
  class: "overflow-auto pb-8",
  style: {"height":"100vh"}
}
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "mt-8 flex flex-wrap items-center justify-between" }
const _hoisted_5 = { class: "flex flex-wrap items-center gap-4" }
const _hoisted_6 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_7 = { class: "flex flex-row gap-4 justify-between items-center" }
const _hoisted_8 = { class: "grid grid-cols-4 gap-4 mr-1" }
const _hoisted_9 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_10 = { class: "flex flex-row gap-4 justify-between items-center" }
const _hoisted_11 = { class: "p-4 grid grid-cols-2 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_SectionTotalTicket = _resolveComponent("SectionTotalTicket")!
  const _component_SectionPercentageDonutChart = _resolveComponent("SectionPercentageDonutChart")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SectionFakeDex = _resolveComponent("SectionFakeDex")!
  const _component_SectionBacklog = _resolveComponent("SectionBacklog")!
  const _component_SectionAttempt = _resolveComponent("SectionAttempt")!
  const _component_SectionBreakdownTicketDex = _resolveComponent("SectionBreakdownTicketDex")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Title, { label: _ctx.title }, null, 8, ["label"]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.filter.startDate,
              endDate: _ctx.filter.endDate,
              onSelect: _ctx.setDateRange,
              default: _ctx.$t('hari ini'),
              isCloseDateAfterSelect: "",
              leftPosition: false,
              maxDate: new Date()
            }, null, 8, ["startDate", "endDate", "onSelect", "default", "maxDate"])
          ])
        ]),
        _createVNode(_component_Card, { class: "mr-1" }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_6, [
              _createVNode("div", _hoisted_7, [
                _createVNode(_component_Title, { label: "Ringkasan Tiket DEX" }),
                _createVNode(_component_lp_button, {
                  icon: "download-new",
                  outline: "",
                  small: "",
                  onClick: _cache[1] || (_cache[1] = () => _ctx.onDownload('summary-ticket/download'))
                })
              ]),
              (_ctx.reRenderComponent)
                ? (_openBlock(), _createBlock(_component_SectionTotalTicket, {
                    key: 0,
                    title: "Total Tiket DEX",
                    type: "all",
                    loading: _ctx.dashboardData.loading
                  }, null, 8, ["loading"]))
                : _createCommentVNode("", true),
              _createVNode("div", _hoisted_8, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.donutChartData, (chart) => {
                  return (_openBlock(), _createBlock(_component_SectionPercentageDonutChart, {
                    key: chart,
                    data: chart,
                    loading: _ctx.dashboardData.loading
                  }, null, 8, ["data", "loading"]))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionFakeDex, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_Card, { class: "mr-1" }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_9, [
              _createVNode("div", _hoisted_10, [
                _createVNode(_component_Title, { label: "Ringkasan Tiket Dex CS" }),
                _createVNode(_component_lp_button, {
                  icon: "download-new",
                  outline: "",
                  small: "",
                  onClick: _cache[2] || (_cache[2] = () => _ctx.onDownload('summary-ticket-cs/download'))
                })
              ]),
              (_ctx.reRenderComponent)
                ? (_openBlock(), _createBlock(_component_SectionTotalTicket, {
                    key: 0,
                    title: "Total Tiket Antrian & Assign CS L1",
                    type: "l1",
                    loading: _ctx.dashboardData.loading
                  }, null, 8, ["loading"]))
                : _createCommentVNode("", true),
              (_ctx.reRenderComponent)
                ? (_openBlock(), _createBlock(_component_SectionTotalTicket, {
                    key: 1,
                    title: "Total Tiket Antrian & Assign CS L2",
                    type: "l2",
                    loading: _ctx.dashboardData.loading
                  }, null, 8, ["loading"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode("div", _hoisted_11, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.backlogType, (item) => {
                return (_openBlock(), _createBlock("div", { key: item }, [
                  (_ctx.reRenderComponent)
                    ? (_openBlock(), _createBlock(_component_SectionBacklog, {
                        key: 0,
                        title: 
                  `Backlog Tiket Diassign & Antrian untuk CS ${_ctx.capitalize(
                    item
                  )} Per Hari`
                ,
                        type: item
                      }, null, 8, ["title", "type"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionAttempt, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionBreakdownTicketDex, { key: 2 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}