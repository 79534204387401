import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_2 = { class: "flex flex-row gap-4 justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_BarCharts = _resolveComponent("BarCharts")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SectionTotalTicket = _resolveComponent("SectionTotalTicket")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Title, { label: "Ringkasan DEX Indikasi Palsu" }),
          _createVNode(_component_lp_button, {
            icon: "download-new",
            outline: "",
            small: "",
            onClick: _ctx.onDownload
          }, null, 8, ["onClick"])
        ]),
        _createVNode(_component_SectionTotalTicket, {
          title: "Total Tiket DEX Indikasi Palsu",
          type: "fake",
          loading: _ctx.dashboardData.loading,
          leftClass: "w-14/20",
          rightClass: "w-6/20",
          ref: "total-ticket-fake"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, { class: "px-3 pt-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_TooltipV2, {
                  text: "Tiket DEX dari Alasan DEX",
                  fontSize: 14,
                  fontWeight: "semibold"
                }),
                _createVNode(_component_BarCharts, _ctx.fakeDexChart, null, 16)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    _: 1
  }))
}