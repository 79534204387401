
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { Vue } from "vue-class-component";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { SummaryTotalDexPerDay } from "@/domain/entities/DexAssessment";

export default class DexAssesmentTab extends Vue {
  mounted(): void {
    this.fetchData();
  }
  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = DexAssessmentController.filter;

    try {
      this.dashboardData.loading = true;
      const res = await DexAssessmentController.getSummaryAttempt(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate)
        })
      );

      const data: SummaryTotalDexPerDay = res.data;
      this.attemptChart = {
        ...this.attemptChart,
        categories: data.labels,
        series: [{ data: data.datasets }]
      };
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }

  attemptChart = {
    series: [] as any[],
    categories: [] as any[],
    height: 200,
    color: ["#58C5E2"],
    rounded: false,
    barHeight: "40%",
    dataLabel: false
  };

  onDownload() {
    DexAssessmentController.getDownload("summary-dex-attempt/download");
  }
}
