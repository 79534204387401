import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_BarCharts = _resolveComponent("BarCharts")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_TooltipV2, {
            text: "Total Percobaan Tiket DEX",
            fontSize: 14,
            fontWeight: "semibold"
          }),
          _createVNode(_component_lp_button, {
            icon: "download-new",
            outline: "",
            small: "",
            onClick: _ctx.onDownload
          }, null, 8, ["onClick"])
        ]),
        (_ctx.dashboardData.loading)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "1rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "20rem"
              })
            ]))
          : (_openBlock(), _createBlock(_component_BarCharts, _mergeProps({ key: 1 }, _ctx.attemptChart), null, 16))
      ])
    ]),
    _: 1
  }))
}