
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { Options, Vue } from "vue-class-component";
import SectionTotalTicket from "../components/section-total-ticket.vue";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";

@Options({
  components: {
    SectionTotalTicket
  }
})
export default class DexAssesmentTab extends Vue {
  mounted(): void {
    this.fetchData();
  }

  // date
  get filter() {
    return DexAssessmentController.filter;
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.dashboardData.loading = true;
      const {
        data: summaryFake
      } = await DexAssessmentController.getSummaryFake(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate)
        })
      );

      this.onMappingFakeBarChartValue(summaryFake);

      const fakeRefs: any = this.$refs["total-ticket-fake"];
      fakeRefs?.onMappingSummaryTicket(summaryFake);
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }
  onMappingFakeBarChartValue(data: any): void {
    this.fakeDexChart.series.forEach((item: any, index: number) => {
      const tempData: number[] = [];
      for (const key of item.key) {
        if (key) {
          tempData.push(data[key]);
        } else {
          tempData.push(0);
        }
      }
      this.fakeDexChart.series[index].data = tempData;
    });
  }

  fakeDexChart = {
    series: [
      {
        name: "Rumah",
        key: [
          "ticketReasonDexOutsideOpsHour",
          "ticketReasonDexClosedAddressHome"
        ],
        data: [] as number[]
      },
      {
        name: "Kantor",
        key: ["", "ticketReasonDexClosedAddressOffice"],
        data: []
      }
    ],
    categories: ["Diluar jam kirim kurir", "Alamat Tutup"],
    height: 150,
    color: ["#8E78DB", "#D2C0FD"]
  };

  onDownload() {
    DexAssessmentController.getDownload("summary-fake-dex/download");
  }
}
